.course-card-tutor {
  webkit-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  padding: 1rem;
  background-color: var(--white-2);
  border-radius: 10px;
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 47%;
  align-items: center;
  height: 300px;
}
.img-container-tutor {
  position: relative;
  flex: 0 1 30%;
}
.info-course-tutor {
  flex: 0 1 67%;
}
.img-container-tutor img {
  width: 200px;
  position: relative;
  object-fit: cover;
}
.info-course-tutor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.top-head-tutor {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.title-course-tutor {
  font-weight: 600;
  padding: 2rem 0;
}
.desc-course-tutor {
  font-size: 0.7rem;
}
.other-course-tutor {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 0;
  gap: 5rem;
  justify-content: space-between;
}
@media(max-width: 1600px){
  .course-card-tutor{
    width: 93%;
  }
}
@media(max-width: 991px){
  .course-card-tutor{
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    
  }

}