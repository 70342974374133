.search-bar-tutor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 2rem;
}
.search-bar-tutor input,
.search-bar-tutor input::placeholder {
  padding: 1rem;
  font-family: "Montserrat", sans-serif;
  opacity: 0.7;
}
.search-bar-tutor input {
  border: 2px solid var(--blue);
  width: 50%;
}
.course-cards{
  display: flex;
  flex-wrap: wrap;
}
.lesson-panel {
  flex: 1 1 30rem;
  overflow-y: scroll;
  height: 100vh;
  padding: 0.4rem;
}

.video-container {
  position: relative;
  width: 100%;
}
.video-container video {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid var(--blue);
}
.lessons-tutor {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0 2rem;
}
.lesson-panel-tutor {
  flex: 0 1 30rem;
}
.add-course input,
.add-course select,
textarea {
  width: 100%;
  border: 1px solid black;
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 1.2rem;
  border-radius: 2px;
  font-family: "Montserrat";
}

.add-course input::placeholder {
  font-size: 1.2rem;
  font-family: "Montserrat";
}
.add-course input:focus {
  border: 2px solid var(--blue);
}
.add-course input ~ span,
.add-course textarea ~ span {
  display: none;
}
.add-course input:invalid ~ span,
.add-course textarea:invalid ~ span {
  display: block;
  color: red;
}
.lesson-panel .desc {
  font-size: 1rem;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
}
.no-info {
  text-align: center;
  margin-top: 20vh;
  font-size: 1.7rem;
  font-weight: bold;
}
.task-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
.task-card-tutor {
  flex: 1 1 100%;
  text-align: center;
}
