.content-tutor .box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.1rem;
}
.content-tutor .box-container .box {
  flex: 1 1 30rem;
  text-align: center;
  padding: 2rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.content-tutor .box-container .box i {
  height: 6rem;
  width: 6rem;
  line-height: 6rem;
  font-size: 2.5rem;
  color: #fff;
  background-color: var(--main-color);
  border-radius: 50%;
}
.content-tutor .box-container .box h3 {
  color: #444;
  font-size: 1.4rem;
  padding-top: 1rem;
}
.content-tutor .box-container .box p {
  color: #444;
  font-size: 1.2rem;
  padding-top: 0.5rem;
}
.form-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 0 1 50%;
  padding: 0.5rem;
}

.form-group img {
  width: 100px;
  border-radius: 50%;
}
.form-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.form-profile {
  width: 80%;
  background-color: var(--white-2);
  padding: 1rem 2rem;
  border-radius: 20px;
  display: block;
  margin: 0 auto;
}
.form-group input {
  border: 1px solid var(--blue);
  padding: 0.3rem 0rem;
  border-radius: 2px;
  width: 100%;
}
