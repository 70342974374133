.challenge-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.challenge-search input {
  border-bottom: 1px solid var(--blue);
  padding: 0.4rem 0;
  opacity: 0.7;
  width: 70%;
  font-size: 1.2rem;
}

.submit-challenge {
  padding: 2rem 1rem;
  flex: 1 1 10rem;
  border-left: 1px solid black;
}
.submit-challenge form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.submit-challenge form label {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: left;
}
.input-field-challenge {
  padding: 0.5rem 0;
}
.submit-challenge form input {
  border: 1px solid black;
  font-size: 1rem;
  padding: 0.6rem 0.2rem;
  border-radius: 5px;
}
.challenge-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
}
.challenge-col {
  display: flex;
}
.challenge-panel {
  flex: 1 1 10rem;
}
.challenge-col2 {
  flex: 1 1 10rem;
}
