.mainbox-livestream {
  margin: auto;
  height: 100vh;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.code-livestream {
  color: var(--blue);
  font-size: 5rem;
  letter-spacing: 10px;
  font-weight: 600;
}
.error-livestream {
  font-size: 3rem;
  text-align: center;
}
.middle {
  width: 200px;
  display: block;
  margin: 5rem auto;
  position: relative;
}
