.card-challenge {
  webkit-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: column;
}
.challenge-col {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.3rem 0;
}
.challenge-col p {
  font-weight: 600;
  font-size: 1.2rem;
}
