.mainbox {
    background-color: var(--white-2);
    margin: auto;
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .code {
    color: var(--white-grey);
    font-size: 12rem;
    letter-spacing: 10px;
    font-weight: 600;
  }
  .error {
    font-size: 5rem;
  }
  