.assignment-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.assignment-search input {
  border-bottom: 1px solid var(--blue);
  padding: 0.4rem 0;
  opacity: 0.7;
  width: 70%;
  font-size: 1.2rem;
}
.course-filter {
  padding: 1rem 0;
}
.course-filter select {
  padding: 1rem;
  border: 1px solid var(--blue);
  width: 100%;
  opacity: 0.7;
}
.submit-form {
  padding: 2rem 1rem;
  flex: 1 1 10rem;
  border-left: 1px solid black;
}
.submit-form form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.submit-form form label {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: left;
}
.input-field-assignment {
  padding: 0.5rem 0;
}
.submit-form form input,
.submit-form form select {
  border: 1px solid black;
  font-size: 1rem;
  padding: 0.6rem 0.2rem;
  border-radius: 5px;
}
.assignment-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.assignment-panel {
  flex: 1 1 10rem;
}
