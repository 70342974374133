.card {
  webkit-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  padding: 0.6rem;
  height: 350px;
  position: relative;
  flex: 0 1 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--white-2);
}
.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: var(--white);
}
.card-info {
  background-color: var(--white-2);
}
.col-1 {
  display: flex;
  padding: 1rem 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-weight: 700;
}
.desc {
  font-size: 0.5rem;
  padding: 0.1rem;
}
