@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --blue-light: #262662;
  --blue: #101050;
  --white: #fff9f9;
  --white-2: #f2f2f8;
  --white-grey: #bbb9d1;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
.heading-center {
  text-align: center;
  font-weight: 700;
  color: var(--blue);
}
body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--white);
  height: 99vh;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}
.heading {
  padding: 1.2rem 0;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 1px;
  width: 100%;
}
section.dash-section {
  min-width: 30vh;
}
section {
  padding-left: 10rem;
  padding-top: 3rem;
}
.content-tutor {
  padding-left: 18rem;
  position: relative;
  padding-top: 5.3rem;
}
.btn {
  padding: 0.5rem 1rem;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 10px;
  font-size: 0.7rem;
  display: inline-block;
  margin: 1rem 0;
}

.btn.small {
  padding: 0.5rem 1rem;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 10px;
  font-size: 0.7rem;
  margin: 1rem 0;
}
.btn.medium {
  padding: 1rem 2rem;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 10px;
  font-size: 1.2rem;
  margin: 1rem 0;
}
.btn.big {
  padding: 2rem 3rem;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 10px;
  font-size: 1.7rem;
  margin: 1rem 0;
}
.btn:hover {
  background-color: var(--white-grey);
  color: var(--blue);
}
.rating {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  overflow-y: auto;
  max-height: 100vh;
}
.modal-content {
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 100vh;
}
.modal-footer,
.modal-header {
  padding: 10px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
}
.modal-header i {
  font-size: 2rem;
}
.modal-title {
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.modal-body {
  padding: 10px;
  border-top: solid 1px #eee;
  border-bottom: 1px solid #eee;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 450px) {
  .modal-content {
    width: 80%;
  }
}
.styled-table {
  width: 80%;
  border-collapse: collapse;
  margin: 2rem auto;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.styled-table thead tr {
  background-color: var(--blue);
  color: #ffffff;
  border-radius: 10px;
  text-align: center;
}

.styled-table th,
.styled-table td {
  padding: 0.5rem 1rem;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  border-radius: 10px;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--blue);
  border-radius: 10px;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: var(--blue);
}
.styled-table tbody tr:hover {
  color: var(--blue);
}
.heading.center {
  text-align: center;
}
@media(max-width: 630px){
  section {
    padding-left: 0rem;
    padding-top: 3rem;
    padding-bottom: 15vh;
  }
  .modal-content {
    width: 95%;

  }
}