.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 2rem;
  webkit-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 7px 17px -3px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  left: 8rem;
  background-color: aliceblue;
}

.right {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}
.right img {
  max-width: 50px;
  border-radius: 50%;
}
.right p {
  font-size: 0.7rem;
}
.right svg {
  font-size: 2rem;
  color: var(--blue);
}
@media(max-width: 630px){
  .nav{
    left: 0rem;
  }
  .right{
    justify-content: space-between  ;
    width: 100%;
  }
}