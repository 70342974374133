
  .wrapper{
    padding: 2rem 1rem;
    color: white;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    background-color: var(--blue-light);
  
  }
  .wrapper-fund{
    display: flex;
    flex-direction: column;
  }
  .jumbotron{
    text-align: center;
    margin-bottom: 1rem;
    background-color: var(--blue);
    color: var(--white);
  }
  .p-input{
    border: 1px solid var(--blue) ;
    border-radius: 5px;
    padding: 1rem;
    margin: .3rem;
    width: 60vw;
}
  .link{
    display: block;
    color: white;
  }
  h2{
    padding: 5rem 1rem;
    text-transform: uppercase;
    font-size: 2rem;
  }
  .column-left{
    flex: 0 1 45%;
    border-right: 1px solid white;
    height: 79vh;
  }
  .column-right{
    padding: 1rem 2rem;
    flex: 0 1 45%;
  }
  .input-row{
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap   ;
  }
  .input-e{
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--white);
    padding: .7rem .4rem;
    width: 70%;
    color: var(--white);
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    margin-top: 1.5rem;
  }
  .select-e{
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--white);
    padding: .7rem .4rem;
    width: 70%;
    color: var(--white);
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    margin-top: 1.5rem;
  }
  .select-e option{
    background-color: var(--blue)
  }
  .padded-p{
    padding: 2rem;
  }
  .text-center{
    padding: 2rem;
  }
  .centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .textarea-e{
    background-color: transparent;
    border: none;
    outline: none;
    border: 1px solid var(--white);
    padding: .7rem .4rem;
    width: 70%;
    color: var(--white);
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    margin-top: 1.5rem;
  }
  .label-e{
    color: grey;
    margin: 2rem .5rem;
  }
  .input-row input, .input-row select{
    width: 30%;
  }
  .error-input{
    background-color: rgb(182, 89, 89);
    padding: .1rem 2rem;
    border-radius: 10px;
    width: 70%;
  }
  @media(max-width: 764px){
    .column-left{
      flex: 0 1 100%;
      border: none;
      border-bottom: 1px solid white;
    }
    .column-right{
      flex: 0 1 100%;
      
    }
    input, select, textarea{
      width: 90%;
    }
  }